import React from "react"
import styled from "styled-components";
import BlogsList from '../Blogs/BlogsList';
import { only } from 'styled-breakpoints';

const Root = styled.div`
  ${only('mini')} {
    margin: 40px 0 50px 0;
  };
`;

const RelatedBlogs = ({ blogs }) => (
  <Root>
    <BlogsList blogs={blogs} />
  </Root>
);

export default RelatedBlogs;
