import React from "react"
import styled, { css } from "styled-components";
import { Text } from 'src/components/Common';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import IconButton from '@mui/material/IconButton';

const Root = styled.div`
  margin: 50px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${props => props.isVertical && css`
    margin: 0;
  `};
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;

  ${props => props.isVertical && css`
    flex-direction: column;
    gap: 0;
  `};
`;

const SharedButtons = ({ url, title, isVertical }) => {
  const shareUrl = `https://appsforce.io/he${url}`;

  return (
    <Root isVertical={isVertical}>
      {title && <Text text={title} variant="h6" isBold />}
      <Buttons isVertical={isVertical}>
        <a href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`} className="blog-share-facebook" rel="noopener" target="_blank">
          <IconButton>
            <FacebookOutlinedIcon fontSize="large" htmlColor="#4367b0" />
          </IconButton>
        </a>

        <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`} className="blog-share-linkedin" rel="noopener" target="_blank">
          <IconButton>
            <LinkedInIcon fontSize="large" htmlColor="#3375b0" />
          </IconButton>
        </a>

        <a href={`https://twitter.com/share?url=${shareUrl}`} className="blog-share-twitter" rel="noopener" target="_blank">
          <IconButton>
            <TwitterIcon fontSize="large" htmlColor="#38a0ef" />
          </IconButton>
        </a>
      </Buttons>
    </Root>
  );
};

export default SharedButtons;
