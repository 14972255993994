import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useLocation } from "@reach/router"
import { articleSchema } from '../schema/article';

export const useArticelSchema = ({ headline, description, image, datePublished }) => {
  const { href } = useLocation();
  const { t } = useTranslation();

  const articleSchemaData = {
    url: href,
    headline: t(headline),
    description: t(description),
    image,
    datePublished
  }

  return articleSchema(articleSchemaData);
}
