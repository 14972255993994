import * as React from "react";
import { graphql } from 'gatsby';
import Blog, { BlogSubHeader } from '../../components/Blog';
import { Paragraph } from "../../components/Common";
import { HE } from '../../blogs/react-vs-angular';
import { ReactVsAngular, WebDevelopmentProcesses, WinningSitesBuiltUsingReact, EmphasisToConsiderWhenDesigningSaaSApp } from '../../blogs';
import { useBreadcrumbsNesteadSchema } from '../../hooks/useBreadcrumbsNesteadSchema';
import { BLOGS_ORIGINAL_BREADCRUMBS } from '../../constants';
import { useArticelSchema } from '../../hooks/useArticelSchema';

const title = "React VS Angular";

const articleSchemaData = {
  headline: title,
  description: "SEO description react vs angular",
  image: "https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Freact_vs_angular.jpg?alt=media&token=704702da-2480-4eb7-bc1c-78373ce589ea",
  datePublished: '2022-02-08'
};

const ReactVsAngularBlog = () => {
  const breadcrumbsElementsSchema = useBreadcrumbsNesteadSchema(title, BLOGS_ORIGINAL_BREADCRUMBS);
  const articelSchema = useArticelSchema(articleSchemaData);

  return (
    <Blog
      url={ReactVsAngular.url}
      relatedBlogs={[WebDevelopmentProcesses, WinningSitesBuiltUsingReact, EmphasisToConsiderWhenDesigningSaaSApp]}
      banner="/react-vs-angular/banner.jpg"
      title="React VS Angular"
      seo={{
        title: 'SEO title react vs angular',
        description: 'SEO description react vs angular',
        image: "https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Freact_vs_angular.jpg?alt=media&token=704702da-2480-4eb7-bc1c-78373ce589ea",
        breadcrumbsElementsSchema,
        articelSchema,
      }}
    >
      {HE.section1.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubHeader>
        {HE.section2.h}
      </BlogSubHeader>
      {HE.section2.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubHeader>
        {HE.section3.h}
      </BlogSubHeader>
      {HE.section3.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubHeader>
        {HE.section4.h}
      </BlogSubHeader>
      {HE.section4.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubHeader>
        {HE.section5.h}
      </BlogSubHeader>
      {HE.section5.p.map(p => <Paragraph>{p}</Paragraph>)}
    </Blog>
  )
}

export default ReactVsAngularBlog;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;