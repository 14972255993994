import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { useLocation } from "@reach/router"

export const useBreadcrumbsNesteadSchema = (title, original = {}) => {
  const { t } = useTranslation();
  const { href } = useLocation();
  const { language } = useI18next();

  const breadcrumbsElementsSchema = [
    {
      "@type": "ListItem",
      "position": 2,
      "name": t(original.name),
      "item": `https://appsforce.io/${language === 'he' ? 'he/' : ''}${original.url}`
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": t(title),
      "item": href
    }
  ];

  return breadcrumbsElementsSchema;
}
