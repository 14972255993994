import React from "react"
import { Link } from 'src/components/Common';
import { URL } from '../url';

export const HE = {
  section1: {
    p: [
      "אנגולר או ריאקט, באיזה פלטפורמה כדאי לפתח אפליקציות Web ואתרים? מה היתרונות ומה החסרונות של כל פלטפורמה לפיתוח? איך לבחור נכון את פלטפורמה הפיתוח המתאימה ביותר לאתר או לאפליקציה שלך?",
      "ובכן, אם הגעת לכאן סימן שאתה מודע לדיון הישן נושן, שכנראה לא יגמר אף פעם, על שני פתרונות  הפיתוח הפופולריים לאתרים ולאפליקציות Web. כדי להבין איזה פלטפורמה עדיפה ומדוע - המומחים של בית התוכנה אפספורס עושים סדר בנושא."
    ],
  },
  section2: {
    h: "מלחמה נושנה בין שני דתות ומאמינים",
    p: [
      "הדיון בנושא Angular לעומת React אינו חדש ודיונים רבים נערכו וימשיכו להיערך סביב השאלה איזה פלטפורמה עדיפה ומתאימות יותר לפיתוח אתרים או אפליקציות. כדי לענות על השאלה פנינו למומחים שלנו בצוות הפיתוח, וכדי להבין אחת ולתמיד מה הכי כדאי לאתר או לאפליקציה אנו מביאים בפניכם את דבריהם.",
      "כדי לענות על השאלה חשוב להבין מה מציעה כל פלטפורמה ואיזה יתרונות וחסרונות יש בה. אנגולר היא פלטפורמה מסוג Framework, כלומר המערכת מציעה כל מה שצריך לפיתוח האתר או האפליקציה שלך. הפיתוח בסביבה זו קל ונוח יותר מאחר ואין צורך לשלב כלים חיצוניים. הפלטפורמה מבוססת על שפת התכנות TypeScript, שפה שפותחה במקור מ-JavaScript, וה- Framework פותח על ידי צוות מחברת גוגל, ובעזרת קהילת מפתחים הנאמנה לפלטפורמה זו.",
      "הגרסה הראשונה של פלטפורמת אנגולר שוחררה בשנת 2010 ובממוצע יש גרסאות חדשות כל כמה חודשים. הפלטפורמה עברה מתיחת פנים ושדרוג משמעותי בשנת 2016, והיא מציעה פתרון שלם ומקיף לפיתוח אתרים ואפליקציות. יחד עם זאת, הפלטפורמה מאוד מורכבת לכן נדרש זמן ממושך ללמוד אותה ולהכיר את כל האפשרויות הטמונות בה, ה-Angular נחשבת לכלי רב עוצמה ומי ששולט בסביבת הפיתוח יכול לקצור פירות וליהנות משפע אפשרויות תעסוקה.",
      "לאנגולר יש חסרונות שכדאי להכיר, החיסרון העיקרי הוא זמן הלימוד הארוך מאחר ויש להשתמש בעקרונות הפיתוח שהותוו ונקבעו על ידי המפתחים של הפלטפורמה הייעודית. אמנם, יש באנגולר ספרייה עצומה וגם קל לייצר פניות ל-API, אך ההיקף העצום של הספריות מחייב הבנה במערכת ויש לפתח שליטה טובה בקונספטים מורכבים ומתקדמים מבחינת תכנות כדי להפיק מהפלטפורמה את המיטב.",
    ],
  },
  section3: {
    h: "תחרות בין שתי ענקיות אינטרנט",
    p: [
      "חשוב לדעת כי ה- Framework מבית גוגל היווה בזמנו בשורה כבירה למפתחי צד לקוח, לכן רבים מהצעירים שלמדו בשנים האחרונות פיתוח פרונט אנד התמקדו בעיקר באנגולר, אך כדי לעבוד עם הפלטפורמה המלוטשת והפופולרית יש להכיר מגוון עקרונות בתחום הפיתוח כגון: Pipes, Services, Directories, Components ועוד. הפלטפורמה עדיין נחשבת לפופולרית אך היא יכולה רק לקנא בפופולריות ובהצלחה של ספריית הפיתוח ריאקט, הפלטפורמה של המתחרים מפייסבוק.",
      "יש לציין כי האנגולר יועדה בתחילת דרכה בעיקר לקהילת המעצבים הגרפיים, אך תוך זמן קצר היא אומצה בחום על ידי מפתחי Web, ורבים הקימו בעזרתה דפים וטפסים המבוססים על HTML בנוחות ובמהירות. כאשר המפתחים נכנסו לעובי הקורה הוקמו מערכות מורכבות ומתקדמות בעזרת הפלטפורמה הייעודית והיא הפכה לתשתית המרכזית לפיתוח אפליקציות ואתרים, כולל אפשרות לפיתוח מערכות Web מורכבות הכוללות אינטראקציה עם המשתמשים וממשקי UX מורכבים ומתוחכמים יותר.",
      "האנגולר מציעה מגוון עצום של רכיבי עיצוב מוכנים ובנויים מראש, כולל טבלאות, מחוונים מתקדמים, לחצנים ועוד. אחד מהיתרונות המרכזיים של Angular הוא התכונה של הזרקת תוכנה, באנגלית Dependency Injection, זו שיטה נוחה ומתוחכמת לתכנון ולפיתוח רכיבי תוכנה המסוגלים לפעול בצורה עצמאית, וריאקט מציעה רק תמיכה חלקית באופציה זו.",
    ],
  },
  section4: {
    h: "ספרייה עצומה למפתחים",
    p: [
      `ריאקט היא למעשה ספרייה המיועדת לפיתוח פרויקטים שונים, לרבות אתרים ואפליקציות לווב. ב-React יש ספרייה של ג'אווה סקריפט שפותחה על ידי המפתחים של פייסבוק, וכיום יש פלטפורמה ייעודית המבוססת עליה ונקראת react native. המערכת מציעה גמישות רבה למפתחים מאחר ואין מבנה מוגדר וקבוע לפיתוח אפליקציה, וניתן למצוא פתרונות שונים לצורך הפיתוח. כך לדוגמה, אפשר לפתח ולכתוב את הקוד של אפליקציה חדשה במבנה MVC המאפשר להשאיר את האפליקציה במצב קריאה לצורך בדיקתה, ואם רוצים "לדבר" עם השרת מתקינים ספרייה ייעודית נוספת, וכאשר מעוניינים לנהל סטייט או להוסיף משהו אחר יש להוסיף ספריות נוספות. לכן, הקונפיגורציה בפרויקטים גדולים ומורכבים עשויה להיות מאתגרת יותר בהשוואה לפיתוח האפליקציה באמצעות פלטפורמת האנגולר.`,
      "לריאקט יש כמה חסרונות מוכרים כמו הצורך להוסיף ספריות, תהליך הקונפיגורציה הנחשב למורכב יחסית, והיא רק ספרייה ולא Framework. עם זאת, יש לריאקט יתרונות חשובים ומשמעותיים בהשוואה לעבודה עם פלטפורמת האנגולר, היתרונות המרכזיים הם העדכונים המהירים, תמיכה זמינה מקהילת מפתחים עצומה ברחבי העולם, תמיכה ארוכת טווח על ידי פייסבוק, אלמנטים של ה-DOM הווירטואלי המשובצים כבר בשרת ומפחיתים את העומס על הדפדפן, והספרייה מבוססת על ג'אווה סקריפט לכן קל יותר ללמוד כיצד לפתח באמצעות הריאקט. בעת פיתוח פרונט אנד רבים מעדיפים להשתמש ב-react בגלל רכיבי הממשק המוכנים, ובנוסף ניתן לכתוב קוד בריאקט באמצעות עורכי קוד רבים כמו Visual Studio, Atom, Sublime Text וכו', ולפיתוח בצד שרת נהוג להשתמש ב-Next.js.",
      "כדאי לדעת כי יש לריאקט גרסה מתקדמת וייחודית הנקראת React Native, הגרסה מתאימה לפיתוח אפליקציות לטלפונים חכמים, ורבים משתמשים בה.",
    ]
  },
  section5: {
    h: "מה עדיף ובאיזה אפשרות כדאי לבחור לפיתוח האתר או האפליקציה?",
    p: [
      <>
        <Link to={URL.HOME} active>המומחים של חברת אפספורס</Link>
        {` מציינים כי בסופו של דבר שתי המערכות יעילות ומאפשרות לפתח באמצעותן אתרים ואפליקציות ל-Web, לכן מדובר בהעדפה אישית של צוות הפיתוח, בכישורים הקיימים ובהרגלים של המפתח. כדי להפוך לטאלנט ולהעמיק את הידע והיכולות `}
        <Link to={URL.FRONT_END_SERVICE} active>בפיתוח פרונט אנד</Link>
        {` כדאי להכיר את שתי המערכות וללמוד כיצד לפתח באמצעותן אתרים ואפליקציות ל-web ולמובייל, וחשוב לדעת כי יש ביקוש גבוה יותר למפתחים בריאקט. `}
      </>
    ]
  },
};