import React from "react";
import styled from "styled-components";
import { MiddleLayout, Text } from "src/components/Common";
import { between, up, only } from "styled-breakpoints";
import { SEO } from "../Common";
import SharedButtons from "./SharedButtons";
import RelatedBlogs from "./RelatedBlogs";
import useMediaQuery from "src/style/useMediaQuery";

const Root = styled.article``;

const Header = styled(Text)`
  text-align: center;
`;

const Article = styled.div`
  max-width: 800px;
  margin: auto;

  ${between("mini", "mobile")} {
    max-width: 100%;
  }
`;

const Content = styled.div`
  position: relative;
`;

const SideSharedButtons = styled.div`
  position: absolute;
  top: -10px;
  right: -65px;

  ${between("mini", "mobile")} {
    display: none;
  }
`;

const TopSharedButtons = styled.div`
  margin-top: -20px;
  ${up("laptop")} {
    display: none;
  } ;
`;

const Banner = styled.div`
  background-image: ${(props) => `url('/images/blogs${props.image}')`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  max-height: 500px;
  height: 23vw;
  width: 100vw;
  margin-bottom: 50px;

  ${only("mini")} {
    height: 35vw;
    margin-bottom: 25px;
  } ;
`;

const BlogOrderListItem = styled.li``;

const BlogUOListItem = styled.li`
  &::before {
    content: "• ";
    font-size: 25px;
  }
`;

const BlogOrderList = styled.ol``;

const BlogUnOrderList = styled.ul`
  margin-top: 10px;
`;

export const BlogSubHeader = ({ children }) => (
  <Text
    variant="h4"
    component="h2"
    sx={{
      marginBottom: 2.5,
      marginTop: 4,
    }}
    isMedium
  >
    {children}
  </Text>
);

export const BlogSubMiniHeader = ({ children }) => (
  <Text
    variant="h5"
    component="h3"
    sx={{
      marginBottom: 1.5,
      marginTop: 2,
    }}
    isMedium
  >
    {children}
  </Text>
);

export const BlogList = ({ children }) => (
  <BlogOrderList>{children}</BlogOrderList>
);

export const BlogListItem = ({ children }) => (
  <BlogOrderListItem>{children}</BlogOrderListItem>
);

export const BlogUnorderList = ({ children }) => (
  <BlogUnOrderList>{children}</BlogUnOrderList>
);

export const BlogUnorderListItem = ({ children }) => (
  <BlogUOListItem>{children}</BlogUOListItem>
);
// `static/images/blogs/${banner}`
const Blog = ({ title, seo, children, url, relatedBlogs, banner }) => {
  const mediaQuery = useMediaQuery();

  return (
    <Root>
      <SEO {...seo} hideAlternateEN />
      <Banner image={banner} />
      <MiddleLayout>
        <Article>
          <Header
            text={title}
            variant="h2"
            component="h1"
            isBold
            sx={{ marginBottom: 5 }}
          />
          <Content>
            {mediaQuery.isMobileOrMini && (
              <TopSharedButtons>
                <SharedButtons url={url} />
              </TopSharedButtons>
            )}
            {children}
            {!mediaQuery.isMobileOrMini && (
              <SideSharedButtons>
                <SharedButtons url={url} isVertical />
              </SideSharedButtons>
            )}
          </Content>
          <SharedButtons url={url} title="Like the article? Spread the word" />
        </Article>
        <RelatedBlogs blogs={relatedBlogs} />
      </MiddleLayout>
    </Root>
  );
};

export default Blog;
