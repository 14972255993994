export const articleSchema = ({ url, headline, description, image, datePublished }) => ({
  "@context": "https://schema.org",
  "@type": "Article",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": url
  },
  "headline": headline,
  "description": description,
  "image": image,
  "author": {
    "@type": "Organization",
    "name": "Appsforce",
    "url": "https://appsforce.io/"
  },
  "publisher": {
    "@type": "Organization",
    "name": "Appsforce",
    "logo": {
      "@type": "ImageObject",
      "url": "https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/favicon1.png?alt=media&token=1e414ce8-2122-4d2b-9745-a596338c52e8"
    }
  },
  "datePublished": datePublished,
  "dateModified": datePublished
});